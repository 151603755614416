import React from 'react';
import { FcPlus} from "react-icons/fc";
import { Link } from 'react-router-dom';
import APIRoute from '../routersAPI/routes.json';



const CreateButton = () => {
    return (
        <div>
            <div className='col-12'>
                    <Link className='btn btn-primary mb-2 float-end' to={APIRoute.createClient}>
                        <FcPlus style={{ fontSize: '25px' }} />&nbsp;Crear nuevo cliente
                    </Link>
                </div>
        </div>
    );
}

export default CreateButton;
